<template>
	<!-- contents -->
	<div class="cw-app-contents">
		<div class="u-tabs-section">
			<v-tabs
				grow
				v-model="tabActiveView"
				:style="tabActive ? '' : 'display: none'"
			>
				<v-tab
					v-for="tab of tabMenuTitle"
					:key="tab.id"
					@click="handleStatusTabActive(tab.id)"
				>
					{{ tab.name }}
				</v-tab>
			</v-tabs>

			<!-- 검색 영역 -->
			<div class="pre-order-search">
				<div class="inner">
					<v-form>
						<div class="select">
							<v-select
								dense
								outlined
								v-model="searchSelect"
								:items="searchItems"
								:item-text="'text'"
								:item-value="'code'"
								hide-details="false"
								append-icon="mdi-chevron-down"
							>
							</v-select>
						</div>
						<div class="keyword">
							<v-text-field
								ref="search_keyword"
								dense
								outlined
								hide-details="false"
								placeholder="검색어 입력"
								append-icon="mdi-magnify"
								@input="changeInput"
								@click:append="clickSearch()"
								@keyup.enter="clickSearch()"
							/>
						</div>
					</v-form>
				</div>
			</div>
			<!--// 검색 영역 -->

			<!-- 합계 영역 -->
			<div class="pre-order-results">
				<div class="inner">
					<span class="total">
						총
						<i>{{ listCount | comma }}</i>
						건
					</span>
					<ul class="status">
						<li>
							<span
								>판매인<i>{{ codyCount }}</i></span
							>
						</li>
						<li>
							<span
								>고객<i>{{ custCount }}</i></span
							>
						</li>
						<li>
							<span
								>코웨이<i>{{ cowayCount }}</i></span
							>
						</li>
					</ul>
					<v-btn text default class="btn-refresh" rounded @click="reload()">
						<span class="ir">새로고침</span>
					</v-btn>
				</div>
			</div>
			<!--// 합계 영역 -->

			<!-- 진행상태, 정렬 -->
			<div class="pre-subs-filter">
				<div class="inner">
					<PopOrderStatus
						title="진행상태"
						v-model="orderStatus"
						:items="orderStatusItems"
						@item-selected="handleStatusSelected"
					>
						<template>
							<em class="value">{{ orderStatusDisplayText }}</em>
							<i class="ico"></i>
						</template>
					</PopOrderStatus>
					<v-btn-toggle
						text
						group
						v-model="sortSelect"
						class="u-toggle-sort"
						mandatory
					>
						<v-btn
							text
							v-for="(sortItem, sortIndex) in sortItems"
							:key="sortIndex"
							@click="handleChangeSort(sortItem)"
							>{{ sortItem.text }}</v-btn
						>
					</v-btn-toggle>
				</div>
			</div>
			<!--// 진행상태, 정렬 -->

			<!-- 조회중 -->
			<!-- 리스트 : 데이터 없음 -->
			<div class="pre-no-data" v-if="searchProgress">
				<img
					src="/img/img_no_data.png"
					alt="조회중입니다. 잠시만 기다려주세요.."
				/>
				<p>조회중입니다.<br />잠시만 기다려주세요.</p>
			</div>
			<!--// 리스트 : 데이터 있음 -->
			<!--// 조회중 -->

			<!-- 리스트 : 데이터 있음 -->
			<div class="pre-order-user" v-else-if="!searchProgress && showList">
				<div class="inner">
					<ul>
						<li
							v-for="(stat, i) in list"
							:key="i"
							:style="
								stat.showStatus &&
								stat.showSearch &&
								stat.showSellType &&
								stat.tabStatus
									? ''
									: 'display:none'
							"
						>
							<div class="state_area">
								<v-btn
									:class="`btn_state type_${stat.statCss}`"
									@click="goPage(stat)"
								>
									{{ getStatNm(stat.statusCode) }}
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
								<div class="label" v-if="stat.info.customer.orderType === 'S'">
									<span>사전예약</span>
								</div>
								<div
									class="label"
									v-else-if="stat.info.customer.orderType === 'C'"
								>
									<span>사전계약</span>
								</div>
								<div
									class="label"
									v-else-if="stat.codyMatch && stat.codyMatch === 'Y'"
								>
									<span>코디매칭</span>
								</div>
							</div>
							<div class="infor_area">
								<p class="cate">
									{{ getCustType(stat.info.customer.custType) }}
								</p>
								<!-- 개인(P) : 이름, 사업자(L): 상호 또는 대포자명 -->
								<p class="name" v-if="stat.info.customer.custType === 'L'">
									{{
										stat.info.customer.bizName
											? stat.info.customer.bizName
											: stat.info.customer.name
									}}
								</p>
								<p class="name" v-else>
									{{ stat.info.customer.name }}
								</p>
								<!--// 개인(P) : 이름, 사업자(L): 상호 -->

								<!-- 주문 상품 정보 -->
								<p
									class="data"
									v-if="stat.info.prdList && stat.info.prdList.length !== 0"
								>
									<em class="type">{{
										stat.info.prdList[0].options.sellType.text
									}}</em>
									<em class="model">{{
										stat.info.changedGoods
											? stat.info.changedGoods.goodsNm
											: stat.info.prdList[0].prd.prod_nm
									}}</em>
									<em
										class="cnt"
										v-if="getProductListCnt(stat.info.prdList) !== 0"
										>외<i>{{ getProductListCnt(stat.info.prdList) }}</i
										>건</em
									>
								</p>
								<!--// 주문 상품 정보 -->

								<!-- 주문자 전화, 문자 -->
								<div class="pre-user-info">
									<div class="contact">
										<a
											:href="`tel:${stat.info.customer.custParams.mob}`"
											class="call"
										>
											<em class="ir">전화</em>
										</a>
										<a
											:href="`sms:${stat.info.customer.custParams.mob}`"
											class="sms"
										>
											<em class="ir">메시지</em>
										</a>
									</div>
								</div>
								<!--// 주문자 전화, 문자 -->
							</div>
						</li>
					</ul>
				</div>
			</div>
			<!--// 리스트 : 데이터 있음 -->

			<!-- 리스트 : 데이터 없음 -->
			<div class="pre-no-data" v-else>
				<img
					src="/img/img_no_data.png"
					alt="사전예약을 신청할 수 있는 계정이
							없습니다."
				/>
				<p>조건에 맞는 주문이 없습니다.<br />검색어를 재확인 바랍니다.</p>
			</div>
			<!--// 리스트 : 데이터 있음 -->

			<!-- 팝업 컴포넌트  -->
			<OrderStatusDefaultDialog
				ref="orderStatusDefaultDialog"
				@reload="reload"
			/>
			<OrderStatusDialog ref="orderStatusDialog" @reload="reload" />
			<OrderStatusReSendDialog ref="orderStatusReSendDialog" @reload="reload" />
			<!-- 팝업 컴포넌트  -->
		</div>
	</div>
	<!-- //contents -->
</template>

<script>
import {
	fetchStatus,
	fetchCody,
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import { sendAlimTalk, fetchEContract } from '@/apis/order.ec.api'
import filters from '@/mixins/filters'
import { registerCallbackToApp } from '@/utils/navigation'
import { chkDeptCd } from '@/utils/ec'
import OrderStatusDefaultDialog from './OrderStatusDefaultDialog.vue'
import OrderStatusDialog from './OrderStatusDialog.vue'
import OrderStatusReSendDialog from './OrderStatusReSendDialog.vue'
import PopOrderStatus from './PopOrderStatus'

export default {
	name: 'OrderStatusPage',
	mixins: [filters],
	components: {
		OrderStatusDefaultDialog,
		OrderStatusDialog,
		OrderStatusReSendDialog,
		PopOrderStatus
	},
	data() {
		return {
			searchProgress: false,
			// 검색 선택항목
			searchSelect: 'NAME',
			searchItems: [
				{ code: 'NAME', text: '고객/상호' },
				{ code: 'PHONE', text: '연락처' }
			],
			// 정렬 선택항목
			sortSelect: 0,
			sortItems: [
				{ code: 'MODIFY', text: '변경순' },
				{ code: 'CREATE', text: '등록순' }
			],
			codyCount: 0, // 판매인
			custCount: 0, // 고객
			cowayCount: 0, // 코웨이

			dept_cd: '',
			list: [],
			searchText: '',
			sortDisplayText: '변경순',
			fromSales: false, // 영업정보시스템에서 접속

			// 필터_01
			orderFilter: 0,
			// 필터_02
			typeFilter: 1,
			// 진행상태
			orderStatus: '',
			orderStatusDisplayText: '진행상태',
			orderStatusItems: [
				{ code: '000', text: '전체보기', isShow: true },
				{ code: '110', text: '인증입력', isShow: true },
				{ code: '102', text: '인증완료', isShow: true },
				{ code: '104', text: '할인입력', isShow: true },
				{ code: '103', text: '이관요청', isShow: true },
				{ code: '105', text: '결제입력', isShow: true },
				{ code: '107', text: '설치입력', isShow: true },
				{ code: '108', text: '서명입력', isShow: true },
				{ code: '112', text: '배정대기', isShow: false },
				{ code: '113', text: '배정진행', isShow: false },
				{ code: '106', text: '서명완료', isShow: true },
				{ code: '111', text: '배정실패', isShow: true },
				{ code: '109', text: '주문확정', isShow: true },
				{ code: '201', text: '주문불가', isShow: true },
				{ code: '202', text: '주문필터', isShow: true },
				{ code: '203', text: '주문삭제', isShow: true }
			],
			tabActive: false,
			tabActiveView: 0, // 0: 일반, 1: 사전계약
			tabMenuTitle: [
				{ id: 0, name: '일반' },
				{ id: 1, name: '사전계약' }
			]
		}
	},
	created() {
		this.initAppPage()
	},

	async mounted() {
		if (['cody', 'home'].includes(this.$store.getters['common/type'])) {
			this.tabActive = true
			if (this.$store.getters['common/tabCode'] === 'C') {
				this.tabActiveView = 1
			}
		}

		await this.orderStatusRequest()

		if (this.$route.query.status) {
			const idx = this.orderStatusItems.findIndex(
				x => x.code === this.$route.query.status
			)
			if (idx > 0) {
				this.handleStatusSelected(this.orderStatusItems[idx])
			}
		}
	},
	computed: {
		showList() {
			let show = false
			let count = 0
			this.list.forEach(stat => {
				if (
					stat.showStatus &&
					stat.showSearch &&
					stat.showSellType &&
					stat.tabStatus
				) {
					count++
				}
			})
			if (count > 0) {
				show = true
			}
			return show
		},

		listCount() {
			let count = 0
			this.list.forEach(stat => {
				if (
					stat.showStatus &&
					stat.showSearch &&
					stat.showSellType &&
					stat.tabStatus
				) {
					count++
				}
			})
			return count
		}
	},
	methods: {
		chkDeptCd,

		// 주문현황 데이터 로드
		async orderStatusRequest() {
			if (this.$route.query.user_no) {
				let userNo = this.$route.query.user_no
				userNo = userNo.replaceAll(' ', '+')
				userNo = userNo.replaceAll('+', '%2B')
				let cody = await fetchCody(encodeURIComponent(userNo))
				this.$route.query.user_no = cody.resultObject.codyNo

				const {
					user_no: codyNo,
					phone_no: codyPhoneNo,
					dept_cd: deptCd,
					type: type,
					fromSales
				} = this.$route.query

				let codyQuery = {}

				if (codyNo) {
					codyQuery.codyNo = codyNo
				} else {
					codyQuery.codyNo = ''
				}

				if (codyPhoneNo) {
					codyQuery.codyPhoneNo = codyPhoneNo
				} else {
					codyQuery.codyPhoneNo = ''
				}

				if (deptCd) {
					codyQuery.deptCd = deptCd
				} else {
					codyQuery.deptCd = ''
				}

				if (type) {
					codyQuery.type = type
				} else {
					codyQuery.type = ''
				}

				if (fromSales) {
					this.fromSales = fromSales
					codyQuery.fromSales = fromSales
				}

				this.$store.dispatch('common/setUserInfo', codyQuery)
			}

			const codyNo = this.$store.getters['common/codyNo']

			this.searchProgress = true
			this.list = []

			await fetchStatus(codyNo)
				.then(res => {
					const RESULT_DATA = res.resultObject
					RESULT_DATA.forEach(async res => {
						const infoObj = JSON.parse(res.orderInfoJson)

						let stat = {
							codyNo: res.codyNo, // 코디번호 코디사번
							codyMatch: res.codyMatch,
							createDate: res.createDate,
							customerNo: res.customerNo,
							id: res.id,
							info: infoObj,
							statusCode: res.statusCode,
							step: res.step,
							updateDate: res.updateDate,
							showStatus: true,
							showSearch: true,
							showSellType: true,
							tabStatus: true
						}

						const statCss = this.getStatCss(res.step, res.statusCode, stat)
						stat.statCss = statCss
						this.list.push(stat)
					})

					this.handleChangeSort(this.sortItems[this.sortSelect])
					this.handleOrderCount()
					if (this.tabActive) this.handleStatusTabActive(this.tabActiveView)
				})
				.catch(error => {
					this.$log('ERROR', error)
				})

			this.searchProgress = false
		},

		// 주문현황 검색
		clickSearch() {
			this.codyCount = 0
			this.custCount = 0
			this.cowayCount = 0

			this.list.forEach((stat, i) => {
				let str = JSON.stringify(stat)

				if (this.searchSelect === 'NAME') {
					str =
						stat.info.customer.custType === 'L' &&
						stat.info.customer.bizName !== undefined &&
						stat.info.customer.bizName !== ''
							? stat.info.customer.bizName
							: stat.info.customer.name
				} else {
					str = stat.info.customer.custParams.mob
				}

				if (str.indexOf(this.searchText) > -1) {
					this.list[i].showSearch = true
				} else {
					this.list[i].showSearch = false
				}
			})
			this.handleOrderCount()
		},

		// 입력한 검색어
		changeInput(e) {
			this.searchText = e.trim()
		},

		// 새로고침
		reload() {
			this.codyCount = 0
			this.custCount = 0
			this.cowayCount = 0
			this.searchSelect = this.searchItems[0].code
			this.sortSelect = 0

			const filed = this.$refs['search_keyword']
			filed.lazyValue = ''

			this.orderStatus = ''
			this.handleStatusSelected(this.orderStatusItems[0])
			this.orderStatusRequest()
		},

		// 진행상태
		handleStatusSelected(item) {
			this.codyCount = 0
			this.custCount = 0
			this.cowayCount = 0

			if (item.code === '000') {
				this.orderStatusDisplayText = '진행상태'
			} else {
				this.orderStatusDisplayText = item.text
			}

			this.list.forEach((stat, i) => {
				if (item.code !== '000') {
					if (stat.statusCode === item.code) {
						this.list[i].showStatus = true
					} else {
						this.list[i].showStatus = false
					}
				} else {
					this.list[i].showStatus = true
				}
			})

			this.handleOrderCount()
		},

		// 탭 전환 시 상태
		handleStatusTabActive(id) {
			this.tabActiveView = id
			const idx1 = this.orderStatusItems.findIndex(v => v.code === '112')
			const idx2 = this.orderStatusItems.findIndex(v => v.code === '113')
			if (!this.tabActive) {
				this.$set(this.orderStatusItems[idx1], 'isShow', false)
				this.$set(this.orderStatusItems[idx2], 'isShow', false)
				return
			}

			const codyQuery = {
				tabCode: id === 0 ? 'N' : 'C' // 0: 일반, 1: 사전게약
			}
			this.$store.dispatch('common/setUserInfo', codyQuery)
			this.codyCount = 0
			this.custCount = 0
			this.cowayCount = 0

			this.list.forEach((stat, i) => {
				if (id === 1) {
					this.$set(this.orderStatusItems[idx1], 'isShow', true)
					this.$set(this.orderStatusItems[idx2], 'isShow', true)
					if (stat.info.customer.orderType === 'C') {
						this.list[i].tabStatus = true
					} else {
						this.list[i].tabStatus = false
					}
				} else {
					this.$set(this.orderStatusItems[idx1], 'isShow', false)
					this.$set(this.orderStatusItems[idx2], 'isShow', false)
					if (stat.info.customer.orderType === 'C') {
						this.list[i].tabStatus = false
					} else {
						this.list[i].tabStatus = true
					}
				}
			})

			this.handleOrderCount()
		},

		handleOrderCount() {
			this.list.forEach((stat, i) => {
				if (
					stat.showStatus &&
					stat.showSearch &&
					stat.showSellType &&
					stat.tabStatus
				) {
					if (this.list[i].statCss === '01') {
						this.codyCount++
					} else if (this.list[i].statCss === '02') {
						this.custCount++
					} else {
						this.cowayCount++
					}
				}
			})
		},

		// 정렬
		handleChangeSort(item) {
			if (item.code === 'MODIFY') {
				this.list.sort((a, b) => {
					if (a.updateDate < b.updateDate) return 1
					if (a.updateDate > b.updateDate) return -1
					return 0
				})
			} else {
				this.list.sort((a, b) => {
					if (a.createDate < b.createDate) return 1
					if (a.createDate > b.createDate) return -1
					return 0
				})
			}
		},

		// 상품 갯수 카운트
		getProductListCnt(prdList) {
			let result = 0
			prdList.forEach(v => {
				result += Number(v.options.quantity)
			})
			return result - 1
		},

		getStatCss(step, statusCode, statObj) {
			/*
				<!--btn_state type_01 코디 - 인증완료, 할인입력, 설치입력, 결제입력, 서명완료, 배정진행, 배정실패 -->
				<!--btn_state type_02 고객 - 인증입력, 서명입력 -->
				<!--btn_state type_03 코웨이 - 이관요청, 주문불가, 주문삭제, 주문확정, 배정대기 -->
			*/
			let stat = ''

			switch (statusCode) {
				case '110': // 인증입력 - 고객직접입력일 때만 생성
					stat = '02'
					break
				case '102': // 인증완료
					stat = '01'
					break
				case '104': // 할인입력
				case '107': // 설치입력
					stat = '01'
					break
				case '105': {
					// 결제입력
					const recpObj = statObj.info.recp
					if (recpObj && recpObj.isCustSelfPay) {
						stat = '02'
					} else {
						stat = '01'
					}
					break
				}
				case '108': {
					// 서명입력
					stat = '02'
					break
				}
				case '106': // 서명완료 - 고객직접수납 전 / 서명완료 후 가상계좌 & 복합결제
					stat = '01'
					break
				case '113': // 배정진행
				case '111': // 배정실패
					stat = '01'
					break
				case '103': // 이관요청
				case '201': // 주문불가
				case '112': // 배정대기
				case '109': // 주문확정
					stat = '03'
					break
				default:
					stat = '03'
					break
			}

			return stat
		},
		getStatNm(statCd) {
			let stat = ''
			const statNmObj = {
				'102': '인증완료',
				'103': '이관요청',
				'104': '할인입력',
				'105': '결제입력',
				'110': '인증입력',
				'111': '배정실패',
				'112': '배정대기',
				'113': '배정진행',
				'106': '서명완료',
				'107': '설치입력',
				'108': '서명입력',
				'109': '주문확정',
				'201': '주문불가',
				'202': '주문필터',
				'203': '주문삭제'
			}
			stat = statNmObj[statCd]
			return stat ? stat : statCd
		},

		getCustType(custType) {
			let custNm = ''
			if (custType === 'P') {
				custNm = '개인'
			} else if (custType === 'L') {
				custNm = '개인사업자'
			}
			return custNm
		},

		async goPage(stat) {
			const info = stat.info
			// 초기화
			this.$store.dispatch('verify/setMobOrderNo', stat.id)
			this.$store.dispatch('verify/setCustomer', info.customer)
			this.$store.dispatch('prd/setPrdList', info.prdList ? info.prdList : [])
			this.$store.dispatch('schedule/setCheckedAll', info.checkedAll)
			this.$store.dispatch(
				'schedule/setDeliveryInfos',
				info.deliveryInfos ? info.deliveryInfos : []
			)

			// 결제정보 세팅
			if (info.pay) {
				this.$log('info.pay')
				this.$store.dispatch(
					'pay/savePayPrd',
					info.pay.products ? info.pay.products : []
				)
			}
			this.$store.dispatch(
				'pay/saveRecpAmt',
				info.RECP_AMT !== undefined ? info.RECP_AMT : 0
			)
			this.$store.dispatch(
				'pay/saveMonthAmtDc',
				info.MONTH_AMT_DC !== undefined ? info.MONTH_AMT_DC : 0
			)
			this.$store.dispatch(
				'pay/savePaymentForOrder',
				info.IT_PAYMENT ? info.IT_PAYMENT : []
			)

			// 설치정보(주문생성결과) 세팅
			this.$store.dispatch('inst/setOrderInfoResult', info.orderInfoResult)

			// 수납정보 세팅
			this.$store.dispatch(
				'recp/setRecpParams',
				info.recp ? info.recp.recpParams : {}
			)
			this.$store.dispatch(
				'recp/setRecpInfos',
				info.recp ? info.recp.recpInfos : {}
			)
			this.$store.dispatch(
				'recp/setVAccountParams',
				info.recp ? info.recp.vaccountParams : {}
			)
			this.$store.dispatch(
				'recp/setDpParams',
				info.recp ? info.recp.dpParams : {}
			)
			this.$store.dispatch(
				'recp/setPayRecp',
				info.recp ? info.recp.payRecp : {}
			)
			this.$store.dispatch(
				'recp/setIsCustSelfPay',
				info.recp ? info.recp.isCustSelfPay : false
			)

			switch (stat.statusCode) {
				case '102': {
					// 인증완료
					const res = await this.openStatusPopup(stat)
					if (res) {
						this.$router.push({ name: 'prd-search' })
					}
					break
				}
				case '103': // 이관요청
				case '110': // 인증입력
				case '202': // 주문필터
				case '203': // 주문취소
					// 기본팝업 실행
					await this.openStatusDefaultPopup(stat)
					break
				case '113': // 배정진행
				case '111': {
					// 배정실패
					const res = await this.openStatusReSendPopup(stat)
					if (res) {
						await this.$router.push({
							name: 'order-schedule-reprogress',
							query: { status: stat.statusCode }
						})
					}
					break
				}
				case '201': {
					// 주문불가
					const res = await this.openStatusPopup(stat)
					if (res) {
						this.$router.push({
							name: 'prd-search'
						})
					}
					break
				}
				case '104': {
					// 할인입력
					const res = await this.openStatusPopup(stat)
					if (res) {
						this.$router.push({
							name: 'order-pay',
							query: { status: stat.statusCode }
						})
					}
					break
				}
				case '105': {
					// 결제입력
					this.$log('stat---', info.recp)
					const recpObj = info.recp
					let cancel = false
					if (recpObj && recpObj.isCustSelfPay) {
						cancel = true
					}
					const res = await this.openStatusPopup(stat, cancel)
					if (res) {
						if (info.recp && info.recp.isCustSelfPay) {
							this.$router.push({
								name: 'ec-cust'
							})
						} else {
							this.$router.push({
								name: 'recp-method',
								query: { status: stat.statusCode }
							})
						}
					}
					break
				}
				case '106': {
					this.$log('서명완료')
					const res = await this.openStatusPopup(stat, false)
					if (res) {
						let statData = stat
						const isPreContract = stat.info.customer.orderType === 'C'
						statData.statusCode = isPreContract ? '112' : '109' // 배정대기 or 주문확정
						await this.resend(isPreContract ? '6' : '2', info) // 배정대기 or 주문확정 알림톡 발송
						this.reload() // 새로고침
						await this.goPage(statData)
					} else {
						let statData = stat
						statData.statusCode = '111' //배정실패
						this.reload() // 새로고침
						await this.goPage(statData)
					}
					break
				}
				case '107': {
					// 설치입력
					const res = await this.openStatusPopup(stat)
					if (res) {
						this.$router.push({
							name: 'order-inst',
							query: { status: stat.statusCode }
						})
					}
					break
				}
				case '108': {
					// 서명입력
					const res = await this.openStatusPopup(stat, true)
					if (res) {
						this.$router.push({
							name: 'order-ec',
							query: { status: stat.statusCode }
						})
					} else {
						this.$router.push({ name: 'status-cancel' })
					}
					break
				}
				case '112': // 배정대기
				case '109': {
					// 주문확정
					const res = await this.openStatusReSendPopup(stat)
					if (res) {
						await this.resend(stat.statusCode === '112' ? '6' : '5', info)
					}
					break
				}
				default:
					break
			}
		},

		async resend(reqCd, info) {
			let ecParams = { mobOrderNo: this.$store.getters['verify/mobOrderNo'] }
			const res = await fetchEContract(ecParams)
			const orderList = res.resultObject.data[0].setup
			const sumAmt = res.resultObject.data[0].sumAmt
			sumAmt.MONTH_AMT_DC = info.MONTH_AMT_DC
			sumAmt.MONTH_AMT_TOTAL = sumAmt.MONTH_AMT - sumAmt.MONTH_AMT_DC

			const webOrderSum = info.PAY_SUMMARY_AMT

			let prdNm = orderList[0] ? orderList[0].GOODS_NM : '제품명'
			let count = orderList && orderList.length > 1 ? orderList.length - 1 : ''
			let planDt = orderList[0] ? orderList[0].FIRST_DT : '예정일'

			let ordNoStr = ''
			orderList.forEach((res, i) => {
				if (i === 0) {
					ordNoStr = `${res.ORDER_NO}`
				} else {
					ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
				}
			})
			const custSelf = this.$store.getters['verify/custSelf']
			let atParams = {
				reqCd: reqCd, // 1 : 계약서발송, 2 : 주문완료&단순배송, 3 : 서명완료재진입, 5 : 주문완료재발송
				gubun: custSelf ? '1' : '2', // 1 : 고객직접, 2 : 코디대행
				name:
					this.$store.getters['verify/customerType'] === 'L'
						? this.$store.getters['verify/bizName']
						: this.$store.getters['verify/custName'],
				phone: this.$store.getters['verify/custPhone'],
				orderNo: ordNoStr,
				orderInfoId: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
				customerNo: this.$store.getters['verify/custNo'],
				codyNo: this.$store.getters['common/codyNo'],
				codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
				goodsNm: prdNm,
				goodsInfo: count !== '' ? `${prdNm} 외 ${count}대` : `${prdNm}`,
				onceAmt: this.$options.filters.comma(webOrderSum.totalSumOfRecpAmt),
				monthAmt: this.$options.filters.comma(webOrderSum.totalSumOfMonthAmt),
				planDt: planDt
			}

			if (reqCd === '3') {
				atParams.url = `${process.env.VUE_APP_FRT_URL}/order/recp/cust/method` // 고객납부 링크
			} else {
				atParams.url = `${process.env.VUE_APP_FRT_URL}/order/confirm` // 주문확정 링크
			}

			const atRes = await sendAlimTalk(atParams)
			// 디버깅용 로그출력
			this.debugUrl(atRes.resultObject.userKey)
		},

		async openStatusDefaultPopup(stat) {
			const statName = this.getStatNm(stat.statusCode)
			const custType = this.getCustType(stat.info.customer.custType)

			if (stat.statusCode === '110') {
				// 인증입력
				const { name, mob } = stat.info.customer.custParams

				return await this.$refs.orderStatusDefaultDialog.open({
					statName,
					name,
					custType,
					mob,
					address: '',
					sellType: '',
					goodsNm: '',
					etcOrderCount: '',
					statusCode: stat.statusCode,
					diffCust: stat.info.diffCust?.message
				})
			} else {
				const { name, mob } = stat.info.customer.custParams
				const { CITY1: address } = stat.info.customer.custInfo

				const sellType =
					stat.info.prdList && stat.info.prdList.length !== 0
						? stat.info.prdList[0].options.sellType.text
						: ''
				const goodsNm =
					stat.info.prdList && stat.info.prdList.length !== 0
						? stat.info.prdList[0].prd.prod_nm
						: ''
				const etcOrderCount =
					stat.info.prdList && stat.info.prdList.length !== 0
						? stat.info.prdList.length - 1
						: ''

				return await this.$refs.orderStatusDefaultDialog.open({
					statName,
					name,
					custType,
					mob,
					address,
					sellType,
					goodsNm,
					etcOrderCount,
					stat
				})
			}
		},

		async openStatusPopup(stat, cancelOrder = false) {
			const statName = this.getStatNm(stat.statusCode)
			const custType = this.getCustType(stat.info.customer.custType)
			const { name, mob } = stat.info.customer.custParams
			const { CITY1: address } = stat.info.customer.custInfo

			const sellType =
				stat.info.prdList && stat.info.prdList.length !== 0
					? stat.info.prdList[0].options.sellType.text
					: ''
			let goodsNm =
				stat.info.prdList && stat.info.prdList.length !== 0
					? stat.info.prdList[0].prd.prod_nm
					: ''
			const changedGoods = stat.info.changedGoods
				? stat.info.changedGoods.goodsNm
				: goodsNm
			if (['108', '106'].includes(stat.statusCode)) {
				const res = await fetchEContract({
					mobOrderNo: this.$store.getters['verify/mobOrderNo']
				})
				const orderList =
					res.resultObject?.data?.length > 0
						? res.resultObject?.data[0].setup
						: undefined
				if (orderList && orderList[0].GOODS_NM) {
					goodsNm = orderList[0].GOODS_NM
					if (changedGoods !== '' && changedGoods !== orderList[0].GOODS_NM) {
						await this.saveWebOrderInfo(orderList[0].GOODS_NM)
						stat.info.changedGoods = new Object()
						this.$set(stat.info.changedGoods, 'goodsNm', goodsNm)
					}
				}
			}

			const etcOrderCount = stat.info.prdList
				? stat.info.prdList.length - 1
				: ''
			const statusCd = stat.statusCode
			return await this.$refs.orderStatusDialog.open({
				statName,
				name,
				custType,
				mob,
				address,
				sellType,
				goodsNm,
				etcOrderCount,
				cancelOrder,
				statusCd,
				stat
			})
		},

		async openStatusReSendPopup(stat) {
			const statName = this.getStatNm(stat.statusCode)
			const custType = this.getCustType(stat.info.customer.custType)
			const { mob } = stat.info.customer.custParams
			const name =
				stat.info.customer.custType === 'L'
					? stat.info.customer.bizName
					: stat.info.customer.name
			const { CITY1: address } = stat.info.customer.custInfo
			const sellType = stat.info.prdList[0].options.sellType.text
			let goodsNm = stat.info.prdList[0].prd.prod_nm
			const changedGoods = stat.info.changedGoods
				? stat.info.changedGoods.goodsNm
				: goodsNm
			const res = await fetchEContract({
				mobOrderNo: this.$store.getters['verify/mobOrderNo']
			})
			const orderList =
				res.resultObject?.data?.length > 0
					? res.resultObject?.data[0].setup
					: undefined
			if (orderList && orderList[0].GOODS_NM) {
				goodsNm = orderList[0].GOODS_NM
				if (changedGoods !== '' && changedGoods !== orderList[0].GOODS_NM) {
					await this.saveWebOrderInfo(orderList[0].GOODS_NM)
					stat.info.changedGoods = new Object()
					this.$set(stat.info.changedGoods, 'goodsNm', goodsNm)
				}
			}
			return await this.$refs.orderStatusReSendDialog.open({
				statName,
				name,
				custType,
				mob,
				address,
				sellType,
				goodsNm,
				stat
			})
		},

		initAppPage() {
			try {
				registerCallbackToApp({ title: '모바일 주문 진행현황' })
			} catch (e) {
				this.$alert({ message: `${e.name}: ${e.message}` })
			}
		},

		debugUrl(userKey) {
			this.$log(
				'url with userKey:',
				`${process.env.VUE_APP_FRT_URL}/order/confirm?userKey=${userKey}`
			)
		},
		async saveWebOrderInfo(goodsNm) {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']

			const res = await fetchWebOrderInfoById(mobOrderNo)
			let webParams = res.resultObject
			let json = JSON.parse(webParams.orderInfoJson)
			json = Object.assign(json, {
				changedGoods: {
					goodsNm: goodsNm
				}
			})
			webParams.orderInfoJson = JSON.stringify(json)
			await postWebOrderInfoById(mobOrderNo, webParams)
		}
	}
}
</script>
